const PlusIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      className={`${props.className ?? 'w-3 h-3 fill-current'}`}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 7C1 6.44772 1.44772 6 2 6H12C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8H2C1.44772 8 1 7.55228 1 7Z' />
      <path d='M7 1C7.55228 1 8 1.44772 8 2L8 12C8 12.5523 7.55228 13 7 13C6.44771 13 6 12.5523 6 12L6 2C6 1.44771 6.44772 1 7 1Z' />
    </svg>
  );
};

export { PlusIcon };

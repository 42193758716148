const CircleMinusIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3 h-3 fill-current'}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='6' fill='#C4C4C4' />
      <path d='M9 5H3V7H9V5Z' fill='black' />
    </svg>
  );
};

function MinusIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className || 'w-3 h-0.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 2'
    >
      <path d='M0 1a1 1 0 011-1h10a1 1 0 110 2H1a1 1 0 01-1-1z'></path>
    </svg>
  );
}

export { CircleMinusIcon, MinusIcon };
